import { types } from "../types/types";
const intitalState = {
  allReports: [],
};

const reportsReducer = (state = intitalState, action) => {
  switch (action.type) {
    case types.CHANGE_REPORTS:
      return {
        ...state,
        allReports: action.payload,
      };
    default:
      return { ...state };
  }
};

export default reportsReducer;
