import React from "react";
import CustomCard from "../../../shared/Card/CustomCard";
import TrackForm from "./TrackForm";

export default function EditTrack({ name }) {
  return (
    <CustomCard title={name}>
      <TrackForm type="EDIT" />
    </CustomCard>
  );
}
