import React, { useEffect } from "react";
import {
  CustomForm,
  CustomInput,
  CustomTextArea,
} from "../../../shared/Input/AllInputs";
import PrimaryButton from "../../../shared/Button/PrimaryButton";
import MenuContainer from "./MenuContainer";
import CustomImageInput from "../../../shared/Input/CustomImageInput";

export default function MenuForm({ type }) {
  const { data, handleChange, loading, onSubmit, setFormType } =
    MenuContainer();
  useEffect(() => {
    setFormType(type);
  }, [type, setFormType]);


  return (
    <>
      <CustomForm style={type === "VIEW" ? { pointerEvents: "none" } : {}}>
         <CustomImageInput
          name="image"
          label="Upload Image"
          col={6}
          onFilesChange={handleChange}
          data={data}
          editable={type !== "VIEW" ? true : false}
        />
         <CustomImageInput
          name="coverImage"
          label="Upload Cover Image"
          col={6}
          onFilesChange={handleChange}
          data={data}
          editable={type !== "VIEW" ? true : false}
        />
        <CustomInput
          label="Name"
          name="name"
          required
          data={data}
          onChange={handleChange}
        />
          <CustomTextArea
          label="Description"
          name="description"
          required
          data={data}
          onChange={handleChange}
        />
        {type !== "VIEW" && (
          <PrimaryButton
            label="Save Category"
            loading={loading}
            onClick={onSubmit}
            col={6}
          />
        )}
      </CustomForm>
    </>
  );
}
