import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  deleteCategoryAction,
  getCategories,
  reorderMenu,
} from "../../redux/actions/locationMenuAction";
import { confirmDelete } from "../../utils/commonFunctions";

export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategories(setLoading));
  }, [dispatch]);

  let { allLocationsMenu } = useSelector((state) => state.locationMenu);

  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");
  const getFilteredData = (data, search) => {
    if (search.length) {
      data = data.filter(
        (item) =>
          item?.name?.toLowerCase()?.includes(search)
      );
    }
    return data;
  };
  const onDelete = (categoryId, position) => {
    confirmDelete(
      () => {
        dispatch(deleteCategoryAction(categoryId));
      },
      "Do you want to delete this Category?",
      position
    );
  };

  const [reorderd, setReorderd] = useState([]);

  useEffect(() => {
    setReorderd(allLocationsMenu);
  }, [allLocationsMenu]);

  const handleChnage = (e) => {
    setReorderd(e.value);
  };

  const onSave = () => {
    dispatch(
      reorderMenu(reorderd, setLoading, () => {
        dispatch(getCategories())
        history.push("/categories/");
      })
    );
  };

  allLocationsMenu = getFilteredData(allLocationsMenu, search);
  return {
    allLocationsMenu,
    loading,
    history,
    search,
    setSearch,
    onDelete,
    handleChnage,
    reorderd,
    onSave,
  };
}
