import { types } from "../types/types";
const intitalState = {
    allTracks: [],
};

const tracksReducer = (state = intitalState, action) => {
    switch (action.type) {
        case types.CHANGE_TRACKS:
            return {
                ...state,
                allTracks: action.payload,
            };
        default:
            return { ...state };
    }
};

export default tracksReducer;
