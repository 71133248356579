import api from "../../services/api";
import Constants from "../../services/constant";
import { uploadImages } from "../../utils/uploadFiles";
import { showToast } from "./toastAction";

export const getUsersCount = (returnData) => async (dispatch) => {
  const res = await api("get", Constants.END_POINT.GET_USERS_COUNT);
  console.log("res====================>", res);
  if (res.success) {
    if (res.data) {
      if (returnData) {
        returnData(res.data);
      }
    }
  }
};

export const sendNotification =
  (data, setLoading, setData) => async (dispatch) => {
    setLoading(true);
    if (data?.newsFeed?.length) {
      let urls = await uploadImages(data.newsFeed);
      data.newsFeed = urls[0];
  }

    const res = await api("post", Constants.END_POINT.NOTIFICATION, data);
    if (res.success) {
      dispatch(showToast({ severity: "success", summary: res.message }));
      setData({
        newsFeed:[]
      });
    } else {
      dispatch(showToast({ severity: "error", summary: res.message }));
      setData({
        newsFeed:[]
      });
    }
    setLoading(false);
  };
