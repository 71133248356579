import React from "react";
import Index from "./index";
import MenuTable from "./MenuTable";

const Menu = () => {
  const {
    allLocationsMenu,
    loading,
    history,
    search,
    setSearch,
    onDelete,
  } = Index();
  return (
    <MenuTable
      allLocationsMenu={allLocationsMenu}
      loading={loading}
      history={history}
      search={search}
      setSearch={setSearch}
      onDelete={onDelete}
    />
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return (
    prevProps.location.pathname === nextProps.location.pathname &&
    prevProps.colorMode === nextProps.colorMode
  );
};
export default React.memo(Menu, comparisonFn);
