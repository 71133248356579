import React from "react";
import Index from "./index";
import ProgramTable from "./ProgramTable";

const Tracks = () => {
  const { allPrograms,
    loading,
    history,
    search,
    setSearch,
    onDelete,
 } = Index();
  return (
    <ProgramTable
      heading="All Programs"
      allPrograms={allPrograms}
      loading={loading}
      history={history}
      search={search}
      setSearch={setSearch}
      onDelete={onDelete}
    />
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return (
    prevProps.location.pathname === nextProps.location.pathname &&
    prevProps.colorMode === nextProps.colorMode
  );
};
export default React.memo(Tracks, comparisonFn);
