
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AudioGallery from "../../assets/images/audioGallery.png";
import InputLayout from "./InputLayout";
import { getImageURL } from "../../utils/imageUrl.js";
import { showToast } from "../../redux/actions/toastAction";
import { ProgressBar } from 'primereact/progressbar';

export default function CustomAudioInput({
    label,
    name,
    onFilesChange,
    errorMessage,
    extraClassName,
    value,
    data,
    limit,
    multiple,
    col,
    required,
    removeable,
    editable,
    ...props
}) {
    const [files, setFiles] = useState(value || data?.[name] || []);
    const dispatch = useDispatch();

    useEffect(() => {
        if (value || data?.[name]) {
            if (JSON.stringify(value || data?.[name]) !== JSON.stringify(files)) {
                setFiles(value || data?.[name]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value || data?.[name]]);

    useEffect(() => {
        if (onFilesChange) {
            onFilesChange({ name, value: files });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files, name]);

    const onDelete = (id) => {
        if (editable) {
            const s = files.filter((item, index) => index !== id);
            setFiles([...s]);
        }
    };

    const onFileChange = (e) => {
        if (editable) {
            const chossenFile = e.target.files;
            handlefileChange(Object.values(chossenFile));
        }
        
    };
    const handlefileChange = (uploadedfile) => {
        let uploadedFiles = [];
        uploadedfile.forEach((file) => {
            if (files.findIndex((f) => f.name === file.name) === -1) {
                uploadedFiles.push(file);
            }
        });
        if (multiple) {
            if (limit) {
                let newarr = [...files, ...uploadedFiles];
                if (newarr.length > limit) {
                    newarr = newarr.slice(0, limit);
                    dispatch(
                        showToast({
                            severity: "warn",
                            summary: "Limit Exceeded",
                            detail: `Max. file limit is ${limit}.`,
                        })
                    );
                }
                setFiles(newarr);
            } else {
                setFiles([...files, ...uploadedFiles]);
            }
        } else {
            setFiles([...uploadedFiles]);
        }
    };
    const progress = useSelector((state) => state?.upload?.progress);
   
      

    return (
        <>
            <input
                type="file"
                id={name}
                name={name}
                onChange={onFileChange}
                onClick={(event) => {
                    event.currentTarget.value = null;
                }}
                accept=".mp3,.mpeg|audio/*"
                hidden
                {...props}

            />
            
            <InputLayout
                col={col || 12}
                label={label || "Upload Audio"}
                name={name}
                required={required}
                extraClassName={extraClassName}
                data={data}
                errorMessage={errorMessage}
            >
                <div className="audio-box">
                    {files && files.length ? (
                        <div className="md:col-12 grid">
                            {Object.values(files).map((audio, i) => (
                                <div
                                    key={i}
                                    className="mr-2 my-1 photoDiv"
                                    style={{
                                        height: "90px",
                                        width: "90px",
                                    }}
                                >
                                    {removeable && (
                                        <i
                                            onClick={() => onDelete(i)}
                                            className="fa-solid fa-circle-xmark cursor-pointer"
                                        ></i>
                                    )}

                                    <label htmlFor={name}>
                                        <audio src={getImageURL(audio)} controls>
                                            <source
                                                src={getImageURL(audio)}
                                                type="audio/mpeg"
                                            />
                                        </audio>
                                    </label>
                                </div>
                            ))}
                            
                        </div>
                        
                    ) : (
                        <div
                            className="photo-upload flex justify-content-center align-items-center"
                            style={{
                                height: "85px",
                                width: "85px",
                            }}
                        >
                            <label htmlFor={name}>
                                <img
                                    className="w-full h-full fit-cover"
                                    src={AudioGallery}
                                    onError={(e) => (e.target.src = "")}
                                    alt=""
                                />
                            </label>
                        </div>
                    )}
                    {progress>0 &&<ProgressBar value={progress} />} 
                </div>
            </InputLayout>
        </>
    );
}
