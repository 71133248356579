import React, { useEffect, useState } from "react";
import { CustomForm, CustomInput } from "../../shared/Input/AllInputs";
import CustomCard from "../../shared/Card/CustomCard";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { getCustomer } from "../../redux/actions/customerAction";
import { CustomPhoneNumber } from "../../shared/Input/CustomPhoneInput";

const ViewCustomerForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [customer, setCustomer] = useState({});
  useEffect(() => {
    if (id) {
      dispatch(
        getCustomer(id, (data) => {
          console.log('dataaaaaaaaaaaaaaaaaaaaaaaa',data)
          setCustomer({
            ...data,
          });
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch]);

  return (
    <CustomCard title="View User Detail" backable>
      <CustomForm>
        <CustomInput label="First Name" value={customer?.fullName} disabled />
        {/* <CustomInput label="Last Name" value={customer?.lastName} disabled /> */}
        <CustomInput label="Email Address" value={customer?.email} disabled />
        <CustomPhoneNumber
          label="Mobile Number"
          value={customer?.countryCode + customer.mobile}
          disabled
        />
      </CustomForm>
    </CustomCard>
  );
};

export default ViewCustomerForm;
