import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Gallery from "../../assets/images/gallery.png";
import InputLayout from "./InputLayout";
import { getImageURL } from "../../utils/imageUrl.js";
import { showToast } from "../../redux/actions/toastAction";

export default function CustomImageVideoInput({
  label,
  name,
  onFilesChange,
  errorMessage,
  extraClassName,
  value,
  data,
  limit,
  multiple,
  col,
  required,
  removeable,
  editable,
  ...props
}) {
  const [files, setFiles] = useState(value || data?.[name] || []);
  const dispatch = useDispatch();

  useEffect(() => {
    if (value || data?.[name]) {
      if (JSON.stringify(value || data?.[name]) !== JSON.stringify(files)) {
        setFiles(value || data?.[name]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value || data?.[name]]);

  useEffect(() => {
    if (onFilesChange) {
        if (files.length) {
      onFilesChange({ name, value: files });
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, name]);

  const onDelete = (id) => {
    if (editable) {
      const s = files.filter((item, index) => index !== id);
      setFiles([...s]);
    }
  };

  const onFileChange = (e) => {
    if (editable) {
      const chosenFiles = e.target.files;
      handleFileChange(Object.values(chosenFiles));
    }
  };

  const handleFileChange = (uploadedFiles) => {
    let validFiles = [];
    uploadedFiles.forEach((file) => {
      if (file.type.startsWith('image/') || file.type.startsWith('video/')) {
        validFiles.push(file);
      } else {
        dispatch(
          showToast({
            severity: "error",
            summary: "Invalid File Type",
            detail: `Please upload only image or video files.`,
          })
        );
      }
    });
    if (multiple) {
      if (limit) {
        let newFiles = [...files, ...validFiles];
        if (newFiles.length > limit) {
          newFiles = newFiles.slice(0, limit);
          dispatch(
            showToast({
              severity: "warn",
              summary: "Limit Exceeded",
              detail: `Max. file limit is ${limit}.`,
            })
          );
        }
        setFiles(newFiles);
      } else {
        setFiles([...files, ...validFiles]);
      }
    } else {
      setFiles([...validFiles]);
    }
  };

  return (
    <>
      <input
        name={name}
        onChange={onFileChange}
        id={name}
        type="file"
        accept="image/*,video/*"
        hidden
        {...props}
        multiple={multiple}
      />
      <InputLayout
        col={col || 12}
        label={label || "Upload Photo/Video"}
        name={name}
        required={required}
        extraClassName={extraClassName}
        data={data}
        errorMessage={errorMessage}
      >
        <div className="image-box">
          {files && files.length ? (
            <div className="md:col-12 grid">
              {Object.values(files).map((file, i) => (
                <div
                  key={i}
                  className="mr-2 my-1 photoDiv"
                  style={{
                    height: "100px",
                    width: "200px",
                  }}
                >
                  {removeable && (
                    <i
                      onClick={() => onDelete(i)}
                      className="fa-solid fa-circle-xmark cursor-pointer"
                    ></i>
                  )}

                  <label htmlFor={name}>
                    {file.type.startsWith('image/') ? (
                      <img
                        className="w-full h-full fit-cover"
                        src={URL.createObjectURL(file)}
                        onError={(e) => (e.target.src = "")}
                        alt=""
                      />
                    ) : file.type.startsWith('video/') ? (
                      <video
                        className="w-full h-full fit-cover"
                        src={URL.createObjectURL(file)}
                        onError={(e) => (e.target.src = "")}
                        controls
                      />
                    ) : null}
                  </label>
                </div>
              ))}
            </div>
          ) : (
            <div
              className="photo-upload flex justify-content-center align-items-center"
              style={{
                height: "85px",
                width: "85px",
              }}
            >
              <label htmlFor={name}>
                <img
                  className="w-full h-full fit-cover"
                  src={Gallery}
                  onError={(e) => (e.target.src = "")}
                  alt=""
                />
              </label>
            </div>
          )}
        </div>
      </InputLayout>
    </>
  );
}
