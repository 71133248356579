import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCustomers } from "../../redux/actions/customerAction";

export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCustomers(setLoading));
  }, [dispatch]);

  let { allCustomers } = useSelector((state) => state.customer);

  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");
  const getFilteredData = (data, search) => {
    if (search.length) {
      data = data.filter(
        (item) =>
          item?.firstName?.includes(search) ||
          item?.lastName?.includes(search) ||
          item?.mobile?.includes(search) ||
          item?.email?.includes(search)
      );
    }
    return data;
  };

  allCustomers = getFilteredData(allCustomers, search);
  let inActiveCustomers = allCustomers.filter((item) => item.isDeleted);

  inActiveCustomers = getFilteredData(inActiveCustomers, search);
  return {
    allCustomers,
    inActiveCustomers,
    loading,
    history,
    search,
    setSearch, 
  };
}
