import React from "react";
import TableCard from "../../shared/TableCard/TableCard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import TableImage from "../../shared/TableCard/TableImage";

export default function ReportsTable({
  heading,
  allReports,
  loading,
  history,
  search,
  setSearch,
  locationId,
}) {
  const image = (r) => {
    const image = r.image;
    return <TableImage image={image} />;
  };

  const actions = (d) => {
    return (
      <>
        <div className="flex justify-content-around">
          <i
            className="pi pi-eye cursor-pointer"
            onClick={() => history.push(`/products/view/${d._id}`)}
          ></i>
        </div>
      </>
    );
  };

  return (
    <TableCard title={heading} onSearch={setSearch} searchKeyword={search}>
      <DataTable
        paginator
        value={allReports}
        showGridlines
        rows={10}
        loading={loading}
        emptyMessage="No Reports found."
      >
        <Column
          field="user.fullName"
          header="Name"
          style={{ width: "250px" }}
        ></Column>
        <Column
          field="title"
          header="Title"
          style={{ width: "250px" }}
        ></Column>
        <Column
          field="isReport"
          header="Status"
          style={{ width: "250px" }}
        ></Column>

        <Column field="comment" header="Comment" style={{ width: "80px" }}></Column>
      </DataTable>
    </TableCard>
  );
}
