import React from "react";
import _ from "lodash";
import InputLayout from "./InputLayout";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { Chips } from "primereact/chips";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { RadioButton } from "primereact/radiobutton";
import { InputMask } from "primereact/inputmask";
import { Calendar } from "primereact/calendar";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import { InputSwitch } from "primereact/inputswitch";

export function CustomForm({ children, ...props }) {
  return (
    <div className="grid grid-nogutter" {...props}>
      {children}
    </div>
  );
}
export const CustomInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  ...props
}) => {
  return (
    <InputLayout
      col={col || 6}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <InputText
        id={name}
        name={name}
        value={value || data?.[name]}
        onChange={(e) =>
          onChange &&
          onChange({ ...e, name: e.target.name, value: e.target.value })
        }
        className={`w-full ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        {...props}
      />
    </InputLayout>
  );
};

export const CustomInputSwitch = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  ...props
}) => {
  return (
    <InputLayout
      col={col || 6}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <InputSwitch
        id={name}
        name={name}
        value={value || data?.[name]}
        checked={value|| data?.[name]}

        onChange={(e) =>
          onChange &&
          onChange({ ...e, name: e.target.name, value: e.value})
        }
        className={` ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        {...props}
      />
    </InputLayout>
  );
};

export const CustomPassword = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  ...props
}) => {
  return (
    <InputLayout
      col={col || 6}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <Password
        toggleMask
        feedback={false}
        id={name}
        name={name}
        value={value || data?.[name]}
        onChange={(e) =>
          onChange &&
          onChange({ ...e, name: e.target.name, value: e.target.value })
        }
        className={`w-full ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        {...props}
      />
    </InputLayout>
  );
};
export const CustomInputNumber = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  ...props
}) => {
  return (
    <InputLayout
      col={col || 6}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <InputNumber
        id={name}
        name={name}
        value={value || data?.[name] || 0}
        onValueChange={(e) =>
          onChange && onChange({ ...e, name: e.target.name, value: e.value })
        }
        className={`w-full ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        useGrouping={props.useGrouping || false}
        {...props}
      />
    </InputLayout>
  );
};

export const CustomCalenderInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  ...props
}) => {
  return (
    <InputLayout
      col={col || 6}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <Calendar
        id={name}
        name={name}
        value={value || data?.[name]}
        onChange={(e) =>
          onChange &&
          onChange({ ...e, name: e.target.name, value: e.target.value })
        }
        className={`w-full ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        showIcon
        {...props}
      />
    </InputLayout>
  );
};

export const CustomInputMask = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  ...props
}) => {
  return (
    <InputLayout
      col={col || 6}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <InputMask
        id={name}
        name={name}
        value={value || data?.[name]}
        onChange={(e) =>
          onChange && onChange({ ...e, name: e.target.name, value: e.value })
        }
        className={`w-full ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        mask="(999) 999-9999"
        {...props}
      />
    </InputLayout>
  );
};

export const CustomTextArea = ({
  label,
  name,
  onChange,
  data,
  value,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  ...props
}) => {
  return (
    <InputLayout
      col={col || 12}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <InputTextarea
        id={name}
        name={name}
        value={value || data?.[name]}
        onChange={(e) =>
          onChange &&
          onChange({ name: e.target.name, value: e.target.value, ...e })
        }
        className={`w-full ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        {...props}
      />
      {errorMessage ? <small className="p-error">{errorMessage}</small> : null}
    </InputLayout>
  );
};
export const CustomChipInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  ...props
}) => {
  return (
    <InputLayout
      col={col || 12}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <Chips
        id={name}
        name={name}
        value={value || data?.[name]}
        onChange={(e) =>
          onChange && onChange({ ...e, name: e.target.name, value: e.value })
        }
        className={`w-full p-fluid ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        placeholder="Press enter to add value."
        {...props}
      />
    </InputLayout>
  );
};
export const CustomDropDown = ({
  label,
  name,
  onChange,
  data,
  value,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  ...props
}) => {
  return (
    <InputLayout
      col={col || 6}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <Dropdown
        id={name}
        name={name}
        value={value || data?.[name]}
        onChange={(e) =>
          onChange && onChange({ ...e, name: e.target.name, value: e.value })
        }
        className={`w-full ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        optionLabel="name"
        placeholder={props.placeholder || `Select ${label}`}
        {...props}
      />
      {errorMessage ? <small className="p-error">{errorMessage}</small> : null}
    </InputLayout>
  );
};
export const CustomMultiSelect = ({
  label,
  name,
  onChange,
  data,
  value,
  options,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  ...props
}) => {
  return (
    <InputLayout
      col={col || 12}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <MultiSelect
        id={name}
        name={name}
        value={options.length ? value || data?.[name] : []}
        onChange={(e) =>
          onChange && onChange({ ...e, name: e.target.name, value: e.value })
        }
        className={`w-full ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        optionLabel="name"
        options={options}
        display="chip"
        placeholder={props.placeholder || `Select ${label}`}
        {...props}
      />
      {errorMessage ? <small className="p-error">{errorMessage}</small> : null}
    </InputLayout>
  );
};

export function CustomRadioButtons({
  label,
  name,
  onChange,
  data,
  value,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  options,
  optionLabel,
  ...props
}) {
  return (
    <InputLayout
      col={col || 12}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <div div className="flex flex-wrap">
        {options.map((item, i) => (
          <div key={i} className={`flex align-items-center mr-6`}>
            <RadioButton
              value={item}
              onChange={(e) =>
                onChange && onChange({ name, value: e.target.value, ...e })
              }
              checked={_.isEqual(value || data?.[name], item)}
              id={name}
              name={item.name}
              {...props}
            />
            &nbsp;&nbsp;
            <label htmlFor={name}>
              {optionLabel ? item[optionLabel] : item?.name}
            </label>
          </div>
        ))}
      </div>
    </InputLayout>
  );
}
export function CustomCheckBox({
  label,
  name,
  onChange,
  data,
  value,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  options,
  optionLabel,
  ...props
}) {
  return (
    <InputLayout
      col={col || 6}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <div div className="flex flex-wrap">
        <div className={`flex align-items-center mr-6`}>
          <Checkbox
            id={name}
            name={name}
            value={value || data?.[name]}
            onChange={(e) =>
              onChange &&
              onChange({ ...e, name: e.target.name, value: !e.target.value })
            }
            {...props}
          />
          &nbsp;&nbsp;
        </div>
      </div>
    </InputLayout>
  );
}
