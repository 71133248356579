export const LevelOptions = [
    {
        name: "Beginner",
        value: "Beginner",
    },
    {
        name: "Experienced",
        value: "Experienced",
    },
];

export const MeditationType = [
    {
        name: "Breathwork Meditation",
        value: "Breathwork Meditation",
    },
    {
        name: "Visualization Meditation",
        value: "Visualization Meditation",
    },
    {
        name: "Guided Meditation",
        value: "Guided Meditation",
    },
    {
        name: "Reiki Meditation",
        value: "Reiki Meditation",
    },
];