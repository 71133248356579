import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
// import { getCustomer, addCustomer, editCustomer } from "../../../../redux/actions/customerActions";
import { allValidations } from "../../../utils/formValidations";
import { showFormErrors } from "../../../utils/commonFunctions";
import { getTracks } from "../../../redux/actions/tracksAction";
import { getImageURL } from "../../../utils/imageUrl";
import {
  addPrograms,
  editProgram,
  getProgram,
} from "../../../redux/actions/programAction";

export default function ProgramContainer() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getTracks());
  }, [dispatch]);

  let { allTracks } = useSelector((state) => state.tracks);
  const [tracks, setTracks] = useState([]);

  const [openTasks, setOpenTasks] = useState(false);
  useEffect(() => {
    if (id) {
      dispatch(
        getProgram(id, (data) => {
          setData({
            coverImage: data.coverImage ? [data.coverImage] : [],
            name: data?.name,
            description: data?.description,
            tracks: data?.tracks,
          });
          const tr = allTracks.filter(
            (item) =>
              !data.tracks.some((trackItem) => trackItem._id === item._id)
          );
          setTracks(tr);
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch, allTracks]);

  useEffect(() => {
    if (!id) {
      setTracks(allTracks);
    }
  }, [allTracks, id]);

  const [formType, setFormType] = useState("ADD");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    coverImage: [],
    name: "",
    description: "",
    tracks: [],
  });

  const handleChange = ({ name, value }) => {
    if (formType !== "VIEW") {
      const formErrors = allValidations(name, value, data);
      setData((prev) => ({ ...prev, [name]: value, formErrors }));
    }
  };

  const handleOnPickerChange = (event) => {
    if (formType !== "VIEW") {
      const formErrors = allValidations("tracks", event.target, data);
      setTracks(event.source);
      setData((prev) => ({ ...prev, tracks: event.target, formErrors }));
    }
  };

  const onSubmit = () => {
    if (showFormErrors(data, setData)) {
      if (formType === "ADD") {
        dispatch(addPrograms(data, setLoading, history));
      } else if (formType === "EDIT") {
        dispatch(editProgram(id, data, setLoading, history));
      }
    }
  };

  const itemTemplate = (item) => {
    return (
      <div className="flex flex-wrap p-2 align-items-center gap-3">
        <img
          className="w-4rem shadow-2 flex-shrink-0 border-round"
          src={getImageURL(item?.image)}
          alt={item.name}
        />
        <div className="flex-1 flex flex-column gap-2 xl:mr-8">
          <span className="font-bold">{item.title}</span>
        </div>
      </div>
    );
  };

  return {
    data,
    handleChange,
    loading,
    onSubmit,
    setFormType,
    setOpenTasks,
    openTasks,
    itemTemplate,
    tracks,
    handleOnPickerChange,
  };
}
