import React from "react";
import TableCard from "../../shared/TableCard/TableCard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import TableImage from "../../shared/TableCard/TableImage";

export default function MenuTable({
  allLocationsMenu,
  loading,
  history,
  search,
  setSearch,
  onDelete,
}) {

  const actions = (d) => {
    return (
      <div className="flex justify-content-around">
        <i
          className="pi pi-eye cursor-pointer"
          onClick={() => history.push(`/category/view/${d._id}`)}
        ></i>
        <i
          className="pi pi-pencil cursor-pointer"
          onClick={() => history.push(`/category/edit/${d._id}`)}
        ></i>
        <i
          className="pi pi-trash cursor-pointer"
          onClick={() => onDelete(d._id)}
        ></i>
      </div>
    );
  };

  const image = (r) => {
    const image = r.image;
    return <TableImage image={image} />;
  };
  const Coverimage = (r) => {
    const image = r.coverImage;
    return <TableImage image={image} />;
  };
  return (
    <TableCard
      title="Category List"
      onSearch={setSearch}
      searchKeyword={search}
      buttonTitle="Add Category"
      linkTo={`/category/add/`}
      extraButtons={[
        { buttonTitle: "Sort", linkTo: `/category/reorder/` },
      ]}
    >
      <DataTable
        paginator
        value={allLocationsMenu}
        className=""
        showGridlines
        rows={10}
        loading={loading}
        responsiveLayout="scroll"
        emptyMessage="No Menu found."
      >
        <Column field="name" header="Name" style={{ width: "250px" }}></Column>
        <Column body={image} header="Image" style={{ width: "200px" }}></Column>
        <Column body={Coverimage} header=" Cover Image" style={{ width: "200px" }}></Column>
        <Column
          body={actions}
          style={{ width: "250px" }}
          header="Action"
        ></Column>
      </DataTable>
    </TableCard>
  );
}
