import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constant";
import { showToast } from "./toastAction";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";
import { uploadImages } from "../../utils/uploadFiles";

const getPrograms = (setLoading) => async (dispatch) => {
    if (setLoading) {
        setLoading(true);
    }
    const res = await api("get", Constants.END_POINT.PROGRAMS);
    if (res.success) {
        if (res.data) {
            dispatch({
                type: types.CHANGE_PROGRAMS,
                payload: res.data,
            });
        }
    }
    if (setLoading) {
        setLoading(false);
    }
};
const addPrograms = (data, setLoading, history) => async (dispatch) => {
    setLoading(true);

    if (data?.coverImage?.length) {
        let urls = await uploadImages(data.coverImage);
        data.coverImage = urls[0];
    }
    const payload = {
        ...data,
        tracks: data?.tracks?.map((item) => item?._id)
    };

    const res = await api("post", Constants.END_POINT.PROGRAMS, payload);
    if (res.success) {
        history.goBack();
        dispatch(getPrograms(null))
        dispatch(showToast({ severity: "success", summary: res.message }));
    }
    setLoading(false);
};
const editProgram =
    (id, data, setLoading, history) => async (dispatch) => {
        setLoading(true);

        if (data?.coverImage?.length) {
            let urls = await uploadImages(data.coverImage);
            data.coverImage = urls[0];
        }
        const payload = {
            ...data,
            tracks: data?.tracks?.map((item) => item?._id)
        };

        const res = await api("put", Constants.END_POINT.PROGRAMS + id, payload);
        if (res.success) {
            getPrograms(null);
            history.goBack();
            dispatch(showToast({ severity: "success", summary: res.message }));
        }
        setLoading(false);
    };

const getProgram = (id, returnData) => async (dispatch) => {
    dispatch(showLoaderAction());
    const res = await api("get", Constants.END_POINT.PROGRAMS + id);
    if (res.success) {
        dispatch(hideLoaderAction());
        if (res.data) {
            if (returnData) {
                returnData(res.data);
            }
        }
    }
    dispatch(hideLoaderAction());
};
const DeleteProgramAction = (id) => async (dispatch) => {
    const res = await api("delete", Constants.END_POINT.PROGRAMS + id);
    if (res.success) {
        dispatch(getPrograms(null));
        dispatch(showToast({ severity: "success", summary: res.message }));
    } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
    }
};
const reorderProgram = (programs, setLoading, next) => async (dispatch) => {
    let payload = programs.map((item) => item._id);
    setLoading(true);
    const res = await api("post", Constants.END_POINT.PROGRAMS + "reorder", payload);
    setLoading(false);
    if (res.success) {
        if (next) {
            next();
        }
    }
};

export { getPrograms, addPrograms, getProgram, editProgram, DeleteProgramAction, reorderProgram };
