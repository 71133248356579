import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
// import { getCustomer, addCustomer, editCustomer } from "../../../../redux/actions/customerActions";
import { allValidations } from "../../../utils/formValidations";
import { showFormErrors } from "../../../utils/commonFunctions";
import {
  addCategory,
  editCategory,
  getCategory,
} from "../../../redux/actions/locationMenuAction";

export default function MenuContainer() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useParams();


  useEffect(() => {
    if (id) {
      dispatch(
        getCategory(id, (data) => {
          setData({
            name: data.name,
            image: data.image ? [data.image] : [],
            coverImage: data.coverImage ? [data.coverImage] : [],
            description: data.description,
          });
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch]);

  const [formType, setFormType] = useState("ADD");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    description: "",
    image: [],
    coverImage: []
  });

  const handleChange = ({ name, value }) => {
    if (formType !== "VIEW") {
      const formErrors = allValidations(name, value, data);
      setData((prev) => ({ ...prev, [name]: value, formErrors }));
    }
  };

  const onSubmit = () => {
    if (showFormErrors(data, setData)) {
      if (formType === "ADD") {
        dispatch(addCategory(data, setLoading, history));
      } else if (formType === "EDIT") {
        dispatch(editCategory(id, data, setLoading, history));
      }
    }
  };

  return { data, handleChange, loading, onSubmit, setFormType };
}
