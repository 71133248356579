import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getReports } from "../../redux/actions/reportsActions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function Index() {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getReports(setLoading));
  }, [dispatch]);
  const { id } = useParams();

  let { allReports } = useSelector((state) => state.reports);

  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");
  const getFilteredData = (data, search) => {
    if (search.length) {
      data = data.filter(
        (item) =>
          item?.name?.toLowerCase()?.includes(search) ||
          item?.description?.toLowerCase()?.includes(search)
      );
    }
    return data;
  };

  allReports = getFilteredData(allReports, search);

  return {
    allReports,
    loading,
    history,
    search,
    setSearch,
    id,
  };
}
