import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constant";

const getReports = (setLoading) => async (dispatch) => {
  if (setLoading) {
    setLoading(true);
  }
  const res = await api("get", Constants.END_POINT.REPORTS);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.CHANGE_REPORTS,
        payload: res.data,
      });
    }
  }
  if (setLoading) {
    setLoading(false);
  }
};

export { getReports };
