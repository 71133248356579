import api from "../../services/api";
import { types } from "../types/types";
import Constants from "../../services/constant";
import { showToast } from "./toastAction";
import { showLoaderAction, hideLoaderAction } from "./loaderAction";

const getCustomers = (setLoading) => async (dispatch) => {
  if (setLoading) {
    setLoading(true);
  }
  const res = await api("get", Constants.END_POINT.CUSTOMER);
  if (res.success) {
    if (res.data) {
      dispatch({
        type: types.CHANGE_CUSTOMERS,
        payload: res.data,
      });
    }
  }
  if (setLoading) {
    setLoading(false);
  }
};

const CustomerEnableDisableAction = (id) => async (dispatch) => {
  const res = await api(
    "put",
    Constants.END_POINT.ENABLE_DISABLE_CUSTOMER + id
  );
  if (res.success) {
    dispatch(getCustomers());
    dispatch(showToast({ severity: "success", summary: res.message }));
  } else {
    dispatch(showToast({ severity: "error", summary: res.message }));
  }
};

const getCustomer = (id, returnData) => async (dispatch) => {
  dispatch(showLoaderAction());
  const res = await api("get", Constants.END_POINT.CUSTOMER + id);
  if (res.success) {
    dispatch(hideLoaderAction());
    if (res.data) {
      console.log("res data", res.data);
      if (returnData) {
        returnData(res.data);
      }
    }
  }
  dispatch(hideLoaderAction());
};

export { getCustomers, getCustomer, CustomerEnableDisableAction };
