import React, { useState, useEffect } from "react";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";
import { AppMenu } from "./AppMenu";
import AppTopBar from "./AppTopBar";
import classNames from "classnames";
import { ConfirmPopup } from "primereact/confirmpopup";
import { ConfirmDialog } from "primereact/confirmdialog";
import "./assets/layout/layout.scss";
import { menuItems } from "./constants/Sidebar";
import ProtectedRoute from "./routes/ProtectedRoute";
import Loader from "./shared/Loader";

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": !isSidebarOpen,
    "layout-static": isSidebarOpen,
    "layout-mobile-sidebar-active": mobileMenuActive,
  });

  useEffect(() => {
    if (!isDesktop()) {
      setIsSidebarOpen(false);
    }
  }, []);

  const toggleSidebar = () => {
    if (isDesktop()) {
      setIsSidebarOpen((prev) => !prev);
    } else {
      if (isSidebarOpen) {
        setIsSidebarOpen(false);
        setMobileMenuActive(false);
        addClass(document.body, "body-overflow-hidden");
      } else {
        setIsSidebarOpen(true);
        setMobileMenuActive(true);
        removeClass(document.body, "body-overflow-hidden");
      }
    }
  };
  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };
  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };
  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  // useEffect(() => {
  //   if (!socket.connected) {
  //     socket.connect();
  //   }
  // }, [socket.connected]);

  // useEffect(() => {
  //   socket.on("connect", () => socket.emit("join", { userId: getMyId() }));
  //   socket.on("join", (e) => console.log("joined==>", e));
  //   socket.on("disconnect", () => console.log("disconnected"));
  //   return () => {
  //     socket.off("join");
  //     socket.off("connect");
  //   };
  // }, [socket.connected]);

  // useEffect(() => {
  //   if (socket.connected) {
  //     socket.emit("join", { userId: getMyId() });
  //   }
  // }, [socket.connected]);

  return (
    <div className={wrapperClass}>
      <AppTopBar toggle={toggleSidebar} />
      <div className="layout-sidebar">
        <AppMenu model={menuItems()} />
      </div>
      <div className="layout-main-container">
        <div className="layout-main">
          <Loader />
          <ConfirmPopup />
          <ConfirmDialog />
          <Switch>
            <ProtectedRoute />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default App;
