import React, { useEffect } from "react";
import {
    CustomForm,
    CustomInput,
    CustomTextArea,
} from "../../../shared/Input/AllInputs";
import PrimaryButton from "../../../shared/Button/PrimaryButton";
import CustomImageInput from "../../../shared/Input/CustomImageInput";
import ProgramContainer from "./ProgramContainer";
import { Dialog } from "primereact/dialog";
import AddTrack from "../../Tracks/TrackForm/AddTrack";
import { PickList } from "primereact/picklist";

export default function ProgramForm({ type }) {
    const { data, handleChange, loading, onSubmit, setFormType, setOpenTasks, openTasks, itemTemplate, tracks, handleOnPickerChange } =
        ProgramContainer();
    useEffect(() => {
        setFormType(type);
    }, [type, setFormType]);

    return (
        <>
            <CustomForm style={type === "VIEW" ? { pointerEvents: "none" } : {}}>
                <CustomImageInput
                    name="coverImage"
                    label="Upload Cover Image"
                    col={6}
                    onFilesChange={handleChange}
                    data={data}
                    editable={type !== "VIEW" ? true : false}
                />
                <CustomInput
                    label="Name"
                    name="name"
                    required
                    data={data}
                    onChange={handleChange}
                />

                <CustomTextArea
                    label="Description"
                    name="description"
                    required
                    data={data}
                    onChange={handleChange}
                />
                <div className="flex justify-content-end w-full "> 
                <div className="flex "> 
                    <PrimaryButton
                    label="Create Track"
                    col={4}
                    extraClassName="text-right"
                    onClick={() => setOpenTasks(true)}
                    />
                </div>
                </div>
                <div className="w-full">
                    <PickList
                        source={tracks}
                        target={data?.tracks}
                        onChange={handleOnPickerChange}
                        itemTemplate={itemTemplate}
                        sourceHeader="All Tracks"
                        targetHeader="Selected Tracks"
                        name="tracks"
                        sourceStyle={{ height: '30rem' }}
                        targetStyle={{ height: '30rem' }}
                        breakpoint="1400px"
                    />
                </div>
                <div className="p-error text-sm">{data?.formErrors?.tracks}</div>
                {type !== "VIEW" && (
                    <PrimaryButton
                        label="Save Programs"
                        loading={loading}
                        onClick={onSubmit}
                        col={6}
                    />
                )}
                {openTasks ? (
                    <Dialog
                        header={"Add Tracks"}
                        visible={openTasks}
                        style={{ width: "48vw" }}
                        draggable={false}
                        resizable={false}
                        onHide={() => setOpenTasks(false)}
                    >
                        <AddTrack backable={false} setOpenTasks={setOpenTasks}/>
                    </Dialog>
                ) : null}
            </CustomForm>
        </>
    );
}
